import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { axiosRequest } from '../common-api/axios';
import { DEF_SORT_ORDER, LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF, STEPS_DEFAULT_SORT_COLUMN } from '../../constants/global';
const initialState = {
    caseTypes: [],
    loading: false,
    listObj: {
        deletedUserProp: false,
        searchKeyProp: "",
        sortOrderProp: DEF_SORT_ORDER,
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
        sortFieldProp: STEPS_DEFAULT_SORT_COLUMN,
    }
};


/**
 * Getting Case Type List
 *
 * @type {*}
 */
export const getCaseTypesListAction = createAsyncThunk(
    "caseTypesModule/getCaseTypesListAction",
    async (value: any) => {
        const apiUrl = value.searchKey === "" ?
            `${EndPoint.CASE_TYPE_LIST_API}?page=${value.currentPage}&size=${value.rows}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedCaseType=${value.deletedUser}` :
            `${EndPoint.CASE_TYPE_LIST_API}?page=${value.currentPage}&size=${value.rows}&search=${value.searchKey}&sort=${value.sortField}&sort_order=${value.sortOrder}&filtereByDeletedCaseType=${value.deletedUser}`;

        try {
            const response = await axiosRequest.get(apiUrl);

            return response;
        } catch (error: any) {
            return error;

        }
    }
);

/**
 * Adding Case Type 
 *
 * @type {*}
 */
export const addCaseTypes = createAsyncThunk(
    "caseTypesModule/addCaseTypes",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.post(`${EndPoint.CASE_TYPE_ADD}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Update Case Type
 *
 * @type {*}
 */
export const updateCaseTypes = createAsyncThunk(
    "caseTypesModule/updateCaseTypes",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_UPDATE}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Update Case Type Status
 *
 * @type {*}
 */
export const updateStatusCaseTypes = createAsyncThunk(
    "caseTypesModule/updateStatusCaseTypes",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.values
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_UPDATE_STATUS}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getCaseTypesById = createAsyncThunk(
    "caseTypesModule/getCaseTypesById",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.CASE_TYPE_BY_ID}/${value.caseTypesID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


/**
 * Deletion Of Case Tyoes
 *
 * @type {*}
 */
export const deleteCaseTypesAction = createAsyncThunk(
    "caseTypesModule/deleteCaseTypesAction",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.delete(`${EndPoint.CASE_TYPE_DELETE_API}/${value.caseTypeId}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


/**
 * Restore Of Case Tyoes
 *
 * @type {*}
 */
export const restoreCaseTypesAction = createAsyncThunk(
    "caseTypesModule/restoreCaseTypesAction",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value._params
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_RESTORE_API}`, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);
/**
 * Adding Stages and Steps to Case Types
 *
 * @type {*}
 */
export const addCaseTypeStageStepsAction = createAsyncThunk(
    "caseTypesModule/addCaseTypeStageStepsAction",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value._params
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_STAGE_STEPS_UPDATE_API}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


/**
 * Adding Forms to Case Types Action
 *
 * @type {*}
 */
export const addCaseTypeFormssAction = createAsyncThunk(
    "caseTypesModule/addCaseTypeFormssAction",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value._params
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_FORMS_UPDATE_API}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Adding Document Checklists to Case Types Action
 *
 * @type {*}
 */
export const addCaseTypeDocumentChecklistsAction = createAsyncThunk(
    "caseTypesModule/addCaseTypeDocumentChecklistsAction",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value._params
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_DOCUMENT_CHECKLIST_UPDATE_API}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Adding Document Questionnaires to Case Types Action
 *
 * @type {*}
 */
export const addCaseTypeQuestionnairesAction = createAsyncThunk(
    "caseTypesModule/addCaseTypeQuestionnairesAction",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value._params
        try {
            const response = await axiosRequest.put(`${EndPoint.CASE_TYPE_QUESTIONNAIRE_UPDATE_API}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getFormScrappingByFormId = createAsyncThunk(
    "caseTypesModule/getFormScrappingByFormId",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.FORM_SCRAPPING_LIST}?form_id=${value.formTypesID}&questionnaire_id=${value.questionnaireID ?? ''}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Set Questions Mapping to Forms
 *
 * @type {*}
 */
export const setQuestionsMappingToForms = createAsyncThunk(
    "caseTypesModule/setQuestionsMappingToForms",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.request
        try {
            const response = await axiosRequest.post(`${EndPoint.ADD_QUESTIONNAIRE_MAPPING}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getFormScrappingByFormIdSignAuth = createAsyncThunk(
    "caseTypesModule/getFormScrappingByFormIdSignAuth",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.get(`${EndPoint.GET_SIGN_AUTH_MAPPING}?form_id=${value.formTypesID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


export const setSigningAuthMappingToForms = createAsyncThunk(
    "caseTypesModule/setSigningAuthMappingToForms",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.request
        try {
            const response = await axiosRequest.post(`${EndPoint.ADD_SIGN_AUTH_MAPPING}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

export const getFormScrappingByFormIdOrgnization = createAsyncThunk(
    "caseTypesModule/getFormScrappingByFormIdOrgnization",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.get(`${EndPoint.GET_ORGANIZATION_MAPPING}?form_id=${value.formTypesID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


export const setOrganizationMappingToForms = createAsyncThunk(
    "caseTypesModule/setOrganizationMappingToForms",
    async (value: any) => {
        delete value.jwtToken;
        const data: any = value.request
        try {
            const response = await axiosRequest.post(`${EndPoint.ADD_ORGANIZATION_MAPPING}`, data);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type Form Mapping By ID
 *
 * @type {*}
 */
export const getFormMappingByFormId = createAsyncThunk(
    "caseTypesModule/getFormMappingByFormId",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.GET_FORM_MAPPING_BY_ID_DETAILS}?questionnaire_id=${value.questionnaireID}&form_id=${value.formTypesID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getCaseTypesTableData = createAsyncThunk(
    "caseTypesModule/getCaseTypesTableData",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.GET_ALL_FORM_MAPPING_ALL}?case_type_id=${value.caseTypesID}&form_id=${value.formID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 * Deletion Of Case Tyoes
 *
 * @type {*}
 */
export const deleteCaseTypesFormMapping = createAsyncThunk(
    "caseTypesModule/deleteCaseTypesFormMapping",
    async (value: any) => {
        delete value.jwtToken;
        try {
            const response = await axiosRequest.delete(`${EndPoint.GET_ALL_FORM_MAPPING_DELETE}/${value.caseTypesID}/${value.formID}/${value.questionnaireID}`);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

/**
 *  Getting Case Type By ID
 *
 * @type {*}
 */
export const getAllFormMappingDetails  = createAsyncThunk(
    "caseTypesModule/getAllFormMappingDetails",
    async (value: any) => {
        delete value.jwtToken;

        try {
            const response = await axiosRequest.get(`${EndPoint.GET_ALL_FORM_MAPPING}?case_type_id=${value.caseTypesID}`,);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


const caseTypesSlice = createSlice({
    name: 'Case Type Management',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.deletedUserProp = actions.payload.deletedUser;
            state.listObj.searchKeyProp = actions.payload.searchKey;
            state.listObj.sortOrderProp = actions.payload.sortOrder;
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
            state.listObj.sortFieldProp = actions.payload.sortField;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCaseTypesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCaseTypesListAction.fulfilled, (state, action) => {
                state.loading = false;
                let data = action.payload.data ? action.payload.data : []
                state.loading = false;
                state.caseTypes = data;
            })
            .addCase(getCaseTypesListAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypes.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCaseTypes.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypes.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCaseTypesById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCaseTypesById.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCaseTypesById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCaseTypes.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCaseTypes.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCaseTypes.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCaseTypesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCaseTypesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteCaseTypesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreCaseTypesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreCaseTypesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreCaseTypesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeStageStepsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCaseTypeStageStepsAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeStageStepsAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeFormssAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCaseTypeFormssAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeFormssAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeDocumentChecklistsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCaseTypeDocumentChecklistsAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeDocumentChecklistsAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeQuestionnairesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCaseTypeQuestionnairesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCaseTypeQuestionnairesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormScrappingByFormId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFormScrappingByFormId.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormScrappingByFormId.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(setQuestionsMappingToForms.pending, (state) => {
                state.loading = true;
            })
            .addCase(setQuestionsMappingToForms.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(setQuestionsMappingToForms.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormMappingByFormId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFormMappingByFormId.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormMappingByFormId.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllFormMappingDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllFormMappingDetails.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllFormMappingDetails.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormScrappingByFormIdSignAuth.pending, (state) => {
                state.loading = true;
            })
            .addCase(getFormScrappingByFormIdSignAuth.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getFormScrappingByFormIdSignAuth.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(setSigningAuthMappingToForms.pending, (state) => {
                state.loading = true;
            })
            .addCase(setSigningAuthMappingToForms.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(setSigningAuthMappingToForms.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(setOrganizationMappingToForms.pending, (state) => {
                state.loading = true;
            })
            .addCase(setOrganizationMappingToForms.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(setOrganizationMappingToForms.rejected, (state, action) => {
                state.loading = false;
            })
            
    }
});

export const caseTypesActions = caseTypesSlice.actions;

export default caseTypesSlice.reducer;