export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ROLE_NAME_REGEX = /^[A-Za-z0-9 ,]+$/;
export const TEAM_NAME_REGEX = /^[A-Za-z0-9 ,]+$/;
export const ROLE_DISC_REGEX = /^[\nA-Za-z0-9 .,_-]+$/;
export const ROLE_SPEC_REGEX = /^[\nA-Za-z0-9 ?'().,_-]+$/;
export const STEPS_DISC_REGEX = /^[A-Za-z0-9 \n.,_(-]+$/;
export const WHITE_SPACE_REGEX = /^\s*\S.*$/;
export const NO_NUMBER_REGEX = /^[^\d]+$/;
export const MAX_SELECT_LABEL_LEGTH = 3;
export const FIRST_LAST_NAME_REGEX = /^[A-Za-z ]+$/;
export const STEP_DAYS_REGEX = /^[0-9]+$/;
export const DOC_CHECKLIST_ITEM_NAME_REGEX = /^[A-Za-z0-9 ,_-]+$/;
export const NUMBER_WITH_7_9_DIGITS_REGEX = /^\d{7,9}$/;
export const DOCUMENT_NAME_REGEX = /^[A-Za-z0-9\-_& ]*$/;

export const IS_MULTI_SELECT_FILTER = true;

export const IO_IOS_SEARCH_SIZE = 21;

export const USER_MAX_LENGTH = 25;

export const USER_ACCESS_MIN = 1;

export const LIST_CRR_PAGE = 1;

export const REACT_TABLE_ORDER = "DSC";

export const DEF_SORT_ORDER = "DESC";

export const ROLE_DEF_COLUMN = "created_at";

export const STEPS_DEF_COLUMN = "created_at";

export const USER_DEF_COLUMN = "created_at";

export const USER_LIST_ROWS_DEF = 10;

export const LIST_ROWS_100 = 100;

export const ROLE_LIST_ROWS_DEF = 10;

export const TEAMS_LIST_ROWS_DEF = 10;

export const TEAMS_DEFAULT_SORT_COLUMN = "created_at";

export const STEPS_LIST_ROWS_DEF = 10;

export const STEPS_DEFAULT_SORT_COLUMN = "created_at";

export const STAGES_LIST_ROWS_DEF = 10;

export const STAGES_DEFAULT_SORT_COLUMN = "created_at";

export const CUSTOM_PAGINATOR_VALUES = [10, 20, 30];

export const PAGINATOR_ROWS_DEF = 10;

export const PAGINATOR_FIRST = 0;

export const DEF_INC_COUNT = 1;

export const EDIT = "edit";

export const VIEW = "view";

export const ADD = "add";

export const VIEWALL = "view-all";

export const MAPPING = "mapping";

export const FORMS = "forms";

export const QUESTIONNAIRES = "questionnaires";

export const POP_UP_CLOSE_1000 = 2000;

export const POP_UP_CLOSE_2000 = 2000;

export const POP_UP_CLOSE_10000 = 10000;

export const DELETE_HEADER_TITLE = "Delete Role";

export const DELETE_ROLE_CONTENT = "Are you sure, you want to delete this role?";
export const DELETE_DESIGNATION_MANAGER_TITLE = "Delete Designation";

export const DELETE_DESIGNATION_MANAGER_CONTENT =
  "Are you sure you want to delete this designation, after deleting this designation it’ll not going to populate in the dropdowns on user selection.";

export const DELETE_STAGE_TITLE = "Delete Stage";

export const DELETE_STAGE_CONTENT =
  "Are you sure, you want to delete the Stage?";

export const DELETE_HEADER_TITLE_STEP = "Delete Step";

export const DELETE_STEP_CONTENT = "Are you sure, you want to delete the Step?";

export const HIDE_TABLE_PAGINATOR = true;

export const REORDERABLE_ROWS = true;

export const IS_SELECT_FILTER = true;

export const DELETE_DOC_CHECKLIST_ITEM_TITLE = "Delete Document Checklist Item";

export const DELETE_DOC_CHECKLIST_ITEM_CONTENT =
  "Are you sure, you want to delete the Document Checklist Item?";

export const DELETE_DOC_TYPE_ITEM_TITLE = "Delete Document Type";

export const DELETE_DOC_TYPE_ITEM_CONTENT =
  "Are you sure, you want to delete the Document Type?";

export const DELETE_RECEIPT_TITLE = "Delete Receipt";

export const DELETE_CHILD_RECEIPT_TITLE = "Delete Receipt";

export const DELETE_RECEIPT_CONTENT =
  "Are you sure, you want to delete All the attached receipts? To remove a single receipt, please click on the eye icon.";

export const DELETE_CHILD_RECEIPT_CONTENT =
  "Are you sure, you want to delete receipt?";

export const DELETE_BENEFICIARY_TITLE = "Delete Beneficiary";

export const DELETE_BENEFICIARY_CONTENT =
  "Are you sure, you want to delete the Beneficiary?";

export const DELETE_DERIVATIVE_TITLE = "Delete Derivative/Co-applicant";

export const DELETE_DERIVATIVE_CONTENT =
  "Are you sure, you want to remove this derivative/co-applicant from this case?";

export const DELETE_PETITIONER_TITLE = "Delete Petitioner/Sponsor";

export const DELETE_PETITIONER_CONTENT =
  "Are you sure, you want to remove this petitioner/sponsor?";

export const MAX_TEN_FILES_ALLOWED = "Maximum ten files are allowed at a time.";

export const MAX_ONE_FILE_ALLOWED = "Maximum one file is allowed.";

export const MAX_ALLOWED_FILES_SIZE_ALLOWED =
  "It seems that one of your file exceed maximum size of 20 MB.";

export const DELETE_DOC_CHECKLIST_TITLE = "Delete Document Checklist";

export const DELETE_DOC_CHECKLIST_CONTENT =
  "Are you sure, you want to delete the Document Checklist?";
export const DELETE_QUESTIONS_TITLE = "Delete Question";

export const DELETE_QUESTIONS_CONTENT =
  "Are you sure you want to delete the Question?";

export const DATE_RANGE_VALUE =
  "Please select start date and end date in the date range value.";
export const DATE_RANGE_VALUE_TWO =
  "Please select end date in the date range value.";

export const DELETE_FORM_MANAGER_TITLE = "Delete Form";

export const DELETE_FORM_MANAGER_CONTENT =
  "Are you sure, you want to delete the form?";
export const DELETE_NOTE_TITLE = "Delete Note";

export const DELETE_NOTE_CONTENT = "Are you sure, you want to delete the Note permanently?";

export const DELETE_DOCUMENT_TITLE = "Delete Document";
export const DELETE_DOCUMENT_CONTENT =
  "Are you sure, you want to delete the Document?";

export const DELETE_FORM_TITLE = "Delete Form";
export const DELETE_FORM_CONTENT =
  "Are you sure, you want to delete the Form?";


export const DELETE_EMAIL_TEMPLATE_TITLE = "Delete Email Template"
export const DELETE_EMAIL_TEMPLATE_CONTENT =
  "Are you sure you want to delete this Email Template?"


export const DELETE_DRAFTS_TITLE = "Delete Draft";
export const DELETE_DRAFTS_CONTENT =
  "Are you sure, you want to delete the draft permanently?";

export const DELETE_ALERT_TITLE = "Delete Alert";
export const DELETE_ALERT_CONTENT =
  "Are you sure, you want to permanently delete the alert?";

export const DELETE_QUESTIONNAIRE_TITLE = "Delete Questionnaire";
export const DELETE_QUESTIONNAIRE_CONTENT =
  "Are you sure, you want to delete the Questionnaire?";

export const DELETE_REMINDER_TITLE = "Delete Reminder";
export const DELETE_REMINDER_CONTENT =
  "Are you sure, you want to delete the Reminder?";

export const DELETE_EVENT_TITLE = "Delete Event";
export const DELETE_EVENT_CONTENT =
  "Are you sure, you want to delete the Event?";

export const DELETE_NOTIFICATION_TITLE = "Delete Notification";
export const DELETE_NOTIFICATION_CONTENT =
  "Are you sure, you want to delete the Notification?";

export const DELETE_REPORT_TITLE = "Delete Report";
export const DELETE_REPORT_CONTENT =
  "Are you sure, you want to delete the Report?";

export const DELETE_RELATIVES_TITLE = "Delete Relatives";
export const DELETE_RELATIVES_CONTENT =
  "Are you sure, you want to delete the Relative?";

export const DELETE_ATTACHMENT_TITLE = "Delete Attachment";
export const DELETE_ATTACHMENT_CONTENT =
  "Are you sure, you want to delete the Attachment?";

export const DEactive_ALERT_TITLE = "Deactivate Alert";
export const DEactive_ALERT_CONTENT =
  "Are you sure, you want to deactivate the alert?";
export const REactive_ALERT_TITLE = "Reactivate Alert";
export const REactive_ALERT_CONTENT =
  "Are you sure, you want to reactivate the alert?";

export const DELETE_FILLING_TITLE = "Delete Filing";
export const DELETE_FILLING_CONTENT =
  "Are you sure, you want to delete this filing?";

export const SEND_DOC_CHK_LIST = "Send Document Checklist";
export const SEND_DOC_CHK_LIST_CONTENT =
  "Are you sure, you want to send the document checklist?";
// GRAPH API
export const IMAGE_NOT_UPLOADED =
  "Profile image not uploaded, Sharepoint issue";
export const ALLOWEDIMAGETYPES =
  "Allowed image file extensions are .jpg, .jpeg and .png.";
export const IMAGESIZE = "Image size must not exceed 2.5 MB.";
export const IMAGESIZEINKB = 2500;
export const ALLOWEDFILETYPES = "Allowed file types are";
export const ALLOWEDFILETYPEPDF = "Only PDF files are allowed for forms.";
export const FILE_NOT_UPLOADED =
  "File not uploaded on sharepoint. Please try again.";
export const FileSIZEINKB = 50000;
export const FILESIZE = "File size must not exceed 50 MB.";
export const PRIMARYCONATCT = "Please select atleast one primary contact."
export const LOGIN_OTHER_BROWSER = "It seems you're logged into other browser tab or window.";
export const USER_NOT_EXIST = "Issue in login please ask your admin to check your account is created or not.";
// User Management Add User Designation Options
export const FILL_FORM_TITLE = "Fill Form with Questionnaire";
export const FILL_FORM__QUESTIONNAIRE_CONTENT =
  "Are you sure, you want to fill form with the associated Questionnaire?";
export const DESIGNATIONS_OPTIONS = [
  { label: "Senior Attorney", value: "Senior Attorney" },
  { label: "Attorneys", value: "Attorneys" },
  { label: "Team Leads", value: "Team Leads" },
  {
    label: "Business Immigration Analyst",
    value: "Business Immigration Analyst",
  },
  { label: "Immigrant Visa Specialists", value: "Immigrant Visa Specialists" },
  { label: "RFE Case Manager", value: "RFE Case Manager" },
  { label: "RFE Attorney", value: "RFE Attorney" },
];

// User Management Add User Department Options
export const DEPARTMENT_OPTIONS = [
  { label: "Human Resources", value: "Human Resources" },
  { label: "Billing", value: "Billing" },
  { label: "Onboarding", value: "Onboarding" },
  { label: "Offboarding", value: "Offboarding" },
  { label: "Administrative", value: "Administrative" },
  { label: "Legal", value: "Legal" },
  { label: "Management", value: "Management" },
  { label: "Business and IT", value: "Business and IT" },
  { label: "Client Liason", value: "Client Liason" }
];

export const ALL = "all";

export const SUCCESS_STATUS = 200;
export const SUCCESS_STATUS_ERROR = 201;

// Role Options
export const roleOptions = [
  { label: "Role 1", value: "Role 1" },
  { label: "Role 2", value: "Role 2" },
  { label: "Role 3", value: "Role 3" },
  { label: "Role 4", value: "Role 4" },
];

// Member Name Options
export const memberNameOptions = [
  { label: "Member Name 1", value: "Member Name 1" },
  { label: "Member Name 2", value: "Member Name 2" },
  { label: "Member Name 3", value: "Member Name 3" },
  { label: "Member Name 4", value: "Member Name 4" },
];

export const sharePointImgUrl =
  "https://matellio068.sharepoint.com/Shared%20Documents/formDataName/Screenshot%20from%202023-09-22%2018-36-37.png";

export const ROLE = "role";

export const ATTORNEYS = "Attorneys";
export const BI_ANALYST = "Business Immigration Analyst";
export const TEAM_LEADS = "Team Leads";
export const SENIOR_ATTORNEY = "Senior Attorney";
export const IMM_VISA_SPECIALISTS = "Immigrant Visa Specialists";
export const RFE_CASE_MANAGER = "RFE Case Manager";
export const RFE_ATTORNEY = "RFE Attorney";

export const foldersNameKnowledgeBaseOptions = [
  { label: "Cover Pages For Filing", value: "Cover Pages For Filing" },
  { label: "Contract Templates", value: "Contract Templates" },
  { label: "Custom Fields", value: "Custom Fields" },
  { label: "Document Checklist", value: "Document Checklist" },
  { label: "Document Templates", value: "Document Templates" },
  { label: "Email Templates", value: "Email Templates" },
  { label: "Forms", value: "Forms" },
  { label: "Letter Templates", value: "Letter Templates" },
  { label: "Questionnaires", value: "Questionnaires" },
  { label: "Refer Keyword", value: "Refer Keyword" },
  { label: "Shipping Information", value: "Shipping Information" },
  { label: "Steps and Reminders", value: "Steps and Reminders" },
];

// Salutation Options
export const salutationOptions = [
  { label: "Dr.", value: "Dr." },
  { label: "Hon.", value: "Hon." },
  { label: "Lic.", value: "Lic." },
  { label: "Miss.", value: "Miss." },
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
  { label: "Sir.", value: "Sir." },
];

// Main Contact Options
export const mainContactOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

// Gender Options
export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

// Gender Options
export const allowedFileTypesOptions = [
  { label: "JPG", value: "JPG" },
  { label: "JPEG", value: "JPEG" },
  { label: "PNG", value: "PNG" },
  { label: "GIF", value: "GIF" },
  { label: "PDF", value: "PDF" },
  { label: "DOC", value: "DOC" },
  { label: "DOCX", value: "DOCX" },
  { label: "PPTX", value: "PPTX" },
  { label: "XLSX", value: "XLSX" },
  { label: "XLS", value: "XLS" },
  { label: "TXT", value: "TXT" },
];

export const DOB_FORMAT = "MM-DD-YYYY";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_EMAIL_REPLY = "MM/DD/YYYY HH:mm:ss"
export const DATE_FORMAT_TIME_ZONE = "MM-DD-YYYY HH:mm:ss.SSSZ"

export const CONTACT_INFO_SCREEN = "Contact Info";

export const COUNTRY_OPTIONS = [
  {
    "value": "Afghanistan",
    "label": "Afghanistan"
  },
  {
    "label": "Albania",
    "value": "Albania"
  },
  {
    "label": "Algeria",
    "value": "Algeria"
  },
  {
    "label": "Andorra",
    "value": "Andorra"
  },
  {
    "label": "Angola",
    "value": "Angola"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "Antigua and Barbuda"
  },
  {
    "label": "Argentina",
    "value": "Argentina"
  },
  {
    "label": "Armenia",
    "value": "Armenia"
  },
  {
    "label": "Australia",
    "value": "Australia"
  },
  {
    "label": "Austria",
    "value": "Austria"
  },
  {
    "label": "Azerbaijan",
    "value": "Azerbaijan"
  },
  {
    "label": "Bahamas",
    "value": "Bahamas"
  },
  {
    "label": "Bahrain",
    "value": "Bahrain"
  },
  {
    "label": "Bangladesh",
    "value": "Bangladesh"
  },
  {
    "label": "Barbados",
    "value": "Barbados"
  },
  {
    "label": "Belarus",
    "value": "Belarus"
  },
  {
    "label": "Belgium",
    "value": "Belgium"
  },
  {
    "label": "Belize",
    "value": "Belize"
  },
  {
    "label": "Benin",
    "value": "Benin"
  },
  {
    "label": "Bhutan",
    "value": "Bhutan"
  },
  {
    "label": "Bolivia",
    "value": "Bolivia"
  },
  {
    "label": "Bosnia and Herzegov",
    "value": "Bosnia and Herzegov"
  },
  {
    "label": "Botswana",
    "value": "Botswana"
  },
  {
    "label": "Brazil",
    "value": "Brazil"
  },
  {
    "label": "Brunei Darussalam",
    "value": "Brunei Darussalam"
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria"
  },
  {
    "label": "Burkina Faso",
    "value": "Burkina Faso"
  },
  {
    "label": "Burundi",
    "value": "Burundi"
  },
  {
    "label": "Cambodia",
    "value": "Cambodia"
  },
  {
    "label": "Cameroon",
    "value": "Cameroon"
  },
  {
    "label": "Canada",
    "value": "Canada"
  },
  {
    "label": "Cape Verde",
    "value": "Cape Verde"
  },
  {
    "label": "Central African Republic",
    "value": "Central African Republic"
  },
  {
    "label": "Chad",
    "value": "Chad"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Colombia",
    "value": "Colombia"
  },
  {
    "label": "Comoros",
    "value": "Comoros"
  },
  {
    "label": "Congo",
    "value": "Congo"
  },
  {
    "label": "Democratic Republic of Congo",
    "value": "Democratic Republic of Congo"
  },
  {
    "label": "Costa Rica",
    "value": "Costa Rica"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "Cuba",
    "value": "Cuba"
  },
  {
    "label": "Cyprus",
    "value": "Cyprus"
  },
  {
    "label": "Czech Republic",
    "value": "Czech Republic"
  },
  {
    "label": "Denmark",
    "value": "Denmark"
  },
  {
    "label": "Djibouti",
    "value": "Djibouti"
  },
  {
    "label": "Dominica",
    "value": "Dominica"
  },
  {
    "label": "Dominican Republic",
    "value": "Dominican Republic"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Egypt",
    "value": "Egypt"
  },
  {
    "label": "El Salvador",
    "value": "El Salvador"
  },
  {
    "label": "Equatorial Guinea",
    "value": "Equatorial Guinea"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Estonia",
    "value": "Estonia"
  },
  {
    "label": "swatini",
    "value": "swatini"
  },
  {
    "label": "Ethiopia",
    "value": "Ethiopia"
  },
  {
    "label": "Fiji",
    "value": "Fiji"
  },
  {
    "label": "Finland",
    "value": "Finland"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "Gabon",
    "value": "Gabon"
  },
  {
    "label": "Gambia",
    "value": "Gambia"
  },
  {
    "label": "Georgia",
    "value": "Georgia"
  },
  {
    "label": "Germany",
    "value": "Germany"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Greece",
    "value": "Greece"
  },
  {
    "label": "Grenada",
    "value": "Grenada"
  },
  {
    "label": "Guatemala",
    "value": "Guatemala"
  },
  {
    "label": "Guinea",
    "value": "Guinea"
  },
  {
    "label": "Guinea-Bissau",
    "value": "Guinea-Bissau"
  },
  {
    "label": "Guyana",
    "value": "Guyana"
  },
  {
    "label": "Haiti",
    "value": "Haiti"
  },
  {
    "label": "Honduras",
    "value": "Honduras"
  },
  {
    "label": "Hungary",
    "value": "Hungary"
  },
  {
    "label": "Iceland",
    "value": "Iceland"
  },
  {
    "label": "India",
    "value": "India"
  },
  {
    "label": "Indonesia",
    "value": "Indonesia"
  },
  {
    "label": "Iran",
    "value": "Iran"
  },
  {
    "label": "Iraq",
    "value": "Iraq"
  },
  {
    "label": "Ireland",
    "value": "Ireland"
  },
  {
    "label": "Israel",
    "value": "Israel"
  },
  {
    "label": "Italy",
    "value": "Italy"
  },
  {
    "label": "Ivory Coast",
    "value": "Ivory Coast"
  },
  {
    "label": "Jamaica",
    "value": "Jamaica"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Jordan",
    "value": "Jordan"
  },
  {
    "label": "Kazakhstan",
    "value": "Kazakhstan"
  },
  {
    "label": "Kenya",
    "value": "Kenya"
  },
  {
    "label": "Kiribati",
    "value": "Kiribati"
  },
  {
    "label": "Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "Kyrgyzstan",
    "value": "Kyrgyzstan"
  },
  {
    "label": "Laos",
    "value": "Laos"
  },
  {
    "label": "Latvia",
    "value": "Latvia"
  },
  {
    "label": "Lebanon",
    "value": "Lebanon"
  },
  {
    "label": "Lesotho",
    "value": "Lesotho"
  },
  {
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "label": "Libya",
    "value": "Libya"
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein"
  },
  {
    "label": "Lithuania",
    "value": "Lithuania"
  },
  {
    "label": "Luxembourg",
    "value": "Luxembourg"
  },
  {
    "label": "Madagascar",
    "value": "Madagascar"
  },
  {
    "label": "Malawi",
    "value": "Malawi"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Marshall Islands",
    "value": "Marshall Islands"
  },
  {
    "label": "Mauritania",
    "value": "Mauritania"
  },
  {
    "label": "Mauritius",
    "value": "Mauritius"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Micronesia",
    "value": "Micronesia"
  },
  {
    "label": "Moldova",
    "value": "Moldova"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Mongolia",
    "value": "Mongolia"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Morocco",
    "value": "Morocco"
  },
  {
    "label": "Mozambique",
    "value": "Mozambique"
  },
  {
    "label": "Myanmar",
    "value": "Myanmar"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Nauru",
    "value": "Nauru"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "North Korea",
    "value": "North Korea"
  },
  {
    "label": "North Macedonia",
    "value": "North Macedonia"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Palau",
    "value": "Palau"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Papua New Guinea",
    "value": "Papua New Guinea"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Philippines",
    "value": "Philippines"
  },
  {
    "label": "Poland",
    "value": "Poland"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Qatar",
    "value": "Qatar"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Russia",
    "value": "Russia"
  },
  {
    "label": "Rwanda",
    "value": "Rwanda"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "Saint Kitts and Nevis"
  },
  {
    "label": "Saint Lucia",
    "value": "Saint Lucia"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "label": "Samoa",
    "value": "Samoa"
  },
  {
    "label": "San Marino",
    "value": "San Marino"
  },
  {
    "label": "Sao Tome and Principe",
    "value": "Sao Tome and Principe"
  },
  {
    "label": "Saudi Arabia",
    "value": "Saudi Arabia"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Seychelles",
    "value": "Seychelles"
  },
  {
    "label": "Sierra Leone",
    "value": "Sierra Leone"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "Slovakia",
    "value": "Slovakia"
  },
  {
    "label": "Slovenia",
    "value": "Slovenia"
  },
  {
    "label": "Solomon Islands",
    "value": "Solomon Islands"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "South Africa",
    "value": "South Africa"
  },
  {
    "label": "South Korea",
    "value": "South Korea"
  },
  {
    "label": "South Sudan",
    "value": "South Sudan"
  },
  {
    "label": "Spain",
    "value": "Spain"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Suriname",
    "value": "Suriname"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Switzerland",
    "value": "Switzerland"
  },
  {
    "label": "Syria",
    "value": "Syria"
  },
  {
    "label": "Tajikistan",
    "value": "Tajikistan"
  },
  {
    "label": "Tanzania",
    "value": "Tanzania"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "Timor-Leste",
    "value": "Timor-Leste"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Tonga",
    "value": "Tonga"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "Trinidad and Tobago"
  },
  {
    "label": "Tunisia",
    "value": "Tunisia"
  },
  {
    "label": "Turkey",
    "value": "Turkey"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Tuvalu",
    "value": "Tuvalu"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "United Arab Emirates",
    "value": "United Arab Emirates"
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom"
  },
  {
    "label": "United States Of America",
    "value": "United States Of America"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Uzbekistan",
    "value": "Uzbekistan"
  },
  {
    "label": "Vanuatu",
    "value": "Vanuatu"
  },
  {
    "label": "Venezuela",
    "value": "Venezuela"
  },
  {
    "label": "Vietnam",
    "value": "Vietnam"
  },
  {
    "label": "Yemen",
    "value": "Yemen"
  },
  {
    "label": "Zambia",
    "value": "Zambia"
  },
  {
    "label": "Zimbabwe",
    "value": "Zimbabwe"
  }
];

export const PHONE_CODE_OPTIONS = [
  { "label": "+93", "value": "+93" },
  { "label": "+358", "value": "+358" },
  { "label": "+355", "value": "+355" },
  { "label": "+213", "value": "+213" },
  { "label": "+1684", "value": "+1684" },
  { "label": "+376", "value": "+376" },
  { "label": "+244", "value": "+244" },
  { "label": "+1264", "value": "+1264" },
  { "label": "+672", "value": "+672" },
  { "label": "+1268", "value": "+1268" },
  { "label": "+54", "value": "+54" },
  { "label": "+374", "value": "+374" },
  { "label": "+297", "value": "+297" },
  { "label": "+61", "value": "+61" },
  { "label": "+43", "value": "+43" },
  { "label": "+994", "value": "+994" },
  { "label": "+1242", "value": "+1242" },
  { "label": "+973", "value": "+973" },
  { "label": "+880", "value": "+880" },
  { "label": "+1246", "value": "+1246" },
  { "label": "+375", "value": "+375" },
  { "label": "+32", "value": "+32" },
  { "label": "+501", "value": "+501" },
  { "label": "+229", "value": "+229" },
  { "label": "+1441", "value": "+1441" },
  { "label": "+975", "value": "+975" },
  { "label": "+591", "value": "+591" },
  { "label": "+387", "value": "+387" },
  { "label": "+267", "value": "+267" },
  { "label": "+55", "value": "+55" },
  { "label": "+246", "value": "+246" },
  { "label": "+673", "value": "+673" },
  { "label": "+359", "value": "+359" },
  { "label": "+226", "value": "+226" },
  { "label": "+257", "value": "+257" },
  { "label": "+855", "value": "+855" },
  { "label": "+237", "value": "+237" },
  { "label": "+1", "value": "+1" },
  { "label": "+238", "value": "+238" },
  { "label": "+ 345", "value": "+ 345" },
  { "label": "+236", "value": "+236" },
  { "label": "+235", "value": "+235" },
  { "label": "+56", "value": "+56" },
  { "label": "+86", "value": "+86" },
  { "label": "+61", "value": "+61" },
  { "label": "+61", "value": "+61" },
  { "label": "+57", "value": "+57" },
  { "label": "+269", "value": "+269" },
  { "label": "+242", "value": "+242" },
  { "label": "+243", "value": "+243" },
  { "label": "+682", "value": "+682" },
  { "label": "+506", "value": "+506" },
  { "label": "+225", "value": "+225" },
  { "label": "+385", "value": "+385" },
  { "label": "+53", "value": "+53" },
  { "label": "+357", "value": "+357" },
  { "label": "+420", "value": "+420" },
  { "label": "+45", "value": "+45" },
  { "label": "+253", "value": "+253" },
  { "label": "+1767", "value": "+1767" },
  { "label": "+1849", "value": "+1849" },
  { "label": "+593", "value": "+593" },
  { "label": "+20", "value": "+20" },
  { "label": "+503", "value": "+503" },
  { "label": "+240", "value": "+240" },
  { "label": "+291", "value": "+291" },
  { "label": "+372", "value": "+372" },
  { "label": "+251", "value": "+251" },
  { "label": "+500", "value": "+500" },
  { "label": "+298", "value": "+298" },
  { "label": "+679", "value": "+679" },
  { "label": "+358", "value": "+358" },
  { "label": "+33", "value": "+33" },
  { "label": "+594", "value": "+594" },
  { "label": "+689", "value": "+689" },
  { "label": "+241", "value": "+241" },
  { "label": "+220", "value": "+220" },
  { "label": "+995", "value": "+995" },
  { "label": "+49", "value": "+49" },
  { "label": "+233", "value": "+233" },
  { "label": "+350", "value": "+350" },
  { "label": "+30", "value": "+30" },
  { "label": "+299", "value": "+299" },
  { "label": "+1473", "value": "+1473" },
  { "label": "+590", "value": "+590" },
  { "label": "+1671", "value": "+1671" },
  { "label": "+502", "value": "+502" },
  { "label": "+44", "value": "+44" },
  { "label": "+224", "value": "+224" },
  { "label": "+245", "value": "+245" },
  { "label": "+595", "value": "+595" },
  { "label": "+509", "value": "+509" },
  { "label": "+379", "value": "+379" },
  { "label": "+504", "value": "+504" },
  { "label": "+852", "value": "+852" },
  { "label": "+36", "value": "+36" },
  { "label": "+354", "value": "+354" },
  { "label": "+91", "value": "+91" },
  { "label": "+62", "value": "+62" },
  { "label": "+98", "value": "+98" },
  { "label": "+964", "value": "+964" },
  { "label": "+353", "value": "+353" },
  { "label": "+44", "value": "+44" },
  { "label": "+972", "value": "+972" },
  { "label": "+39", "value": "+39" },
  { "label": "+1876", "value": "+1876" },
  { "label": "+81", "value": "+81" },
  { "label": "+44", "value": "+44" },
  { "label": "+962", "value": "+962" },
  { "label": "+77", "value": "+77" },
  { "label": "+254", "value": "+254" },
  { "label": "+686", "value": "+686" },
  { "label": "+850", "value": "+850" },
  { "label": "+82", "value": "+82" },
  { "label": "+965", "value": "+965" },
  { "label": "+996", "value": "+996" },
  { "label": "+856", "value": "+856" },
  { "label": "+371", "value": "+371" },
  { "label": "+961", "value": "+961" },
  { "label": "+266", "value": "+266" },
  { "label": "+231", "value": "+231" },
  { "label": "+218", "value": "+218" },
  { "label": "+423", "value": "+423" },
  { "label": "+370", "value": "+370" },
  { "label": "+352", "value": "+352" },
  { "label": "+853", "value": "+853" },
  { "label": "+389", "value": "+389" },
  { "label": "+261", "value": "+261" },
  { "label": "+265", "value": "+265" },
  { "label": "+60", "value": "+60" },
  { "label": "+960", "value": "+960" },
  { "label": "+223", "value": "+223" },
  { "label": "+356", "value": "+356" },
  { "label": "+692", "value": "+692" },
  { "label": "+596", "value": "+596" },
  { "label": "+222", "value": "+222" },
  { "label": "+230", "value": "+230" },
  { "label": "+262", "value": "+262" },
  { "label": "+52", "value": "+52" },
  { "label": "+691", "value": "+691" },
  { "label": "+373", "value": "+373" },
  { "label": "+377", "value": "+377" },
  { "label": "+976", "value": "+976" },
  { "label": "+382", "value": "+382" },
  { "label": "+1664", "value": "+1664" },
  { "label": "+212", "value": "+212" },
  { "label": "+258", "value": "+258" },
  { "label": "+95", "value": "+95" },
  { "label": "+264", "value": "+264" },
  { "label": "+674", "value": "+674" },
  { "label": "+977", "value": "+977" },
  { "label": "+31", "value": "+31" },
  { "label": "+599", "value": "+599" },
  { "label": "+687", "value": "+687" },
  { "label": "+64", "value": "+64" },
  { "label": "+505", "value": "+505" },
  { "label": "+227", "value": "+227" },
  { "label": "+234", "value": "+234" },
  { "label": "+683", "value": "+683" },
  { "label": "+672", "value": "+672" },
  { "label": "+1670", "value": "+1670" },
  { "label": "+47", "value": "+47" },
  { "label": "+968", "value": "+968" },
  { "label": "+92", "value": "+92" },
  { "label": "+680", "value": "+680" },
  { "label": "+970", "value": "+970" },
  { "label": "+507", "value": "+507" },
  { "label": "+675", "value": "+675" },
  { "label": "+595", "value": "+595" },
  { "label": "+51", "value": "+51" },
  { "label": "+63", "value": "+63" },
  { "label": "+872", "value": "+872" },
  { "label": "+48", "value": "+48" },
  { "label": "+351", "value": "+351" },
  { "label": "+1939", "value": "+1939" },
  { "label": "+974", "value": "+974" },
  { "label": "+40", "value": "+40" },
  { "label": "+7", "value": "+7" },
  { "label": "+250", "value": "+250" },
  { "label": "+262", "value": "+262" },
  { "label": "+590", "value": "+590" },
  { "label": "+290", "value": "+290" },
  { "label": "+1869", "value": "+1869" },
  { "label": "+1758", "value": "+1758" },
  { "label": "+590", "value": "+590" },
  { "label": "+508", "value": "+508" },
  { "label": "+1784", "value": "+1784" },
  { "label": "+685", "value": "+685" },
  { "label": "+378", "value": "+378" },
  { "label": "+239", "value": "+239" },
  { "label": "+966", "value": "+966" },
  { "label": "+221", "value": "+221" },
  { "label": "+381", "value": "+381" },
  { "label": "+248", "value": "+248" },
  { "label": "+232", "value": "+232" },
  { "label": "+65", "value": "+65" },
  { "label": "+421", "value": "+421" },
  { "label": "+386", "value": "+386" },
  { "label": "+677", "value": "+677" },
  { "label": "+252", "value": "+252" },
  { "label": "+27", "value": "+27" },
  { "label": "+211", "value": "+211" },
  { "label": "+500", "value": "+500" },
  { "label": "+34", "value": "+34" },
  { "label": "+94", "value": "+94" },
  { "label": "+249", "value": "+249" },
  { "label": "+597", "value": "+597" },
  { "label": "+47", "value": "+47" },
  { "label": "+268", "value": "+268" },
  { "label": "+46", "value": "+46" },
  { "label": "+41", "value": "+41" },
  { "label": "+963", "value": "+963" },
  { "label": "+886", "value": "+886" },
  { "label": "+992", "value": "+992" },
  { "label": "+255", "value": "+255" },
  { "label": "+66", "value": "+66" },
  { "label": "+670", "value": "+670" },
  { "label": "+228", "value": "+228" },
  { "label": "+690", "value": "+690" },
  { "label": "+676", "value": "+676" },
  { "label": "+1868", "value": "+1868" },
  { "label": "+216", "value": "+216" },
  { "label": "+90", "value": "+90" },
  { "label": "+993", "value": "+993" },
  { "label": "+1649", "value": "+1649" },
  { "label": "+688", "value": "+688" },
  { "label": "+256", "value": "+256" },
  { "label": "+380", "value": "+380" },
  { "label": "+971", "value": "+971" },
  { "label": "+44", "value": "+44" },
  { "label": "+1", "value": "+1" },
  { "label": "+598", "value": "+598" },
  { "label": "+998", "value": "+998" },
  { "label": "+678", "value": "+678" },
  { "label": "+58", "value": "+58" },
  { "label": "+84", "value": "+84" },
  { "label": "+1284", "value": "+1284" },
  { "label": "+1340", "value": "+1340" },
  { "label": "+681", "value": "+681" },
  { "label": "+967", "value": "+967" },
  { "label": "+260", "value": "+260" },
  {
    "label": "+263", "value": "+263"
  }
]

export const MAX_DATE = new Date();

export const QUESTION_TYPES = [
  { label: "Text box", value: "TextBox" },
  { label: "Text area", value: "TextArea" },
  { label: "Drop-down", value: "Dropdown" },
  { label: "Checkbox", value: "Checkbox" },
  { label: "Date picker", value: "DatePicker" },
  { label: "Multi-select", value: "MultiSelect" },
  { label: "Radio", value: "Radio" },
];

export const CASE_TYPE_MAPPING = {
  STAGES: "stages",
  QUESTIONNAIRE: "questionnaire",
  DOCUMENTS: "documents",
  FORMS: "forms",
  QUESTIONNAIRE_MAPPING: "questionnaire-mapping",
  FORM_MAPPING: "form-mapping"
};

export const LANGUAGES_OPTIONS = [
  { label: "English", value: "English" },
  { label: "Spanish", value: "Spanish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Other", value: "Other" },
];

export const PRIORITY_OPTIONS = [
  { label: "Low", value: "Low" },
  { label: "Normal", value: "Normal" },
  { label: "High", value: "High" },
];

export const REMINDER_OPTIONS = [
  { label: "3 days", value: "3" },
  { label: "7 days", value: "7" },
  { label: "15 days", value: "15" },
];

export const UNSAVED_CHANGES_INFO = "Are you sure you want to leave, You have unsaved changes?";

export const APARTMENT_TYPES = [
  { label: "Apt.", value: "Apt." },
  { label: "Door", value: "Door" },
  { label: "Floor", value: "Floor" },
  { label: "Suite", value: "Suite" },
  { label: "Unit", value: "Unit" },
];

export const RELATIONSHIP_OPTIONS = [
  { label: "Wife", value: "Wife" },
  { label: "Husband", value: "Husband" },
  { label: "Former spouse", value: "Former spouse" },
  { label: "Child", value: "Child" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Stepson", value: "Stepson" },
  { label: "Stepdaughter", value: "Stepdaughter" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Fiance/Fiancee", value: "Fiance/Fiancee" },
  { label: "Son-in-Law", value: "Son-in-Law" },
  { label: "Daughter-In-Law", value: "Daughter-In-Law" },
  { label: "Brother-in-Law", value: "Brother-in-Law" },
  { label: "Sister-in-Law", value: "Sister-in-Law" },
  { label: "Common Law Partner", value: "Common Law Partner" },
  { label: "Domestic Partner", value: "Domestic Partner" },
  { label: "Caregiver", value: "Caregiver" },
  { label: "Legal Guardian", value: "Legal Guardian" },
  { label: "Other", value: "Other" },
  { label: "Co-applicant", value: "Co-applicant" },
];

export const SELECT_ONE_NOTE = "Select atleast one record to export"

// Case Tabs ID
export const CASE_TABS_ID = {
  CLIENT_DETAILS: 'clientDetails',
  COMMUNICATION: 'communication',
  EMAILS: 'emails',
  DRAFTS: 'drafts',
  COMPOSE_EMAIL: 'composeEmail',
  PIPE_NOTES: 'pipeNotes',
  ALL_CASES: 'allCases',
  BILLING: 'billing',
  ALERTS: 'alerts',
};

export const ALERTS_TYPES = [
  { label: "Red", value: "Red" },
  { label: "Green", value: "Green" },
  { label: "Orange", value: "Orange" },
];

export const MODE_OF_CREATION_OPTIONS = [
  { label: "Mode of Creation: All", value: "" },
  { label: "PipeDrive", value: "PipeDrive" },
  { label: "CHWizard(or Manual)", value: "Manual" }
];

export const BILLING_STATUS_OPTIONS = [
  { label: "Overdue", value: "Overdue" },
  { label: "Paid", value: "Paid" },
  { label: "Ontime", value: "Ontime" }
];

export const MAIN_CONTACTS_OPTIONS = [
  { label: "Main Contact: All", value: "" },
  { label: "Yes", value: "True" },
  { label: "No", value: "False" }
];

export const CURRENTLY_IN_US_OPTIONS = [
  { label: "Currently in US: All", value: "" },
  { label: "Yes", value: "True" },
  { label: "No", value: "False" }
];


// Roles heading
export const TEAMS_PERMISSION_HEADING = "Manage Team";
export const USERS_PERMISSION_HEADING = "Manage User";
export const DERIVATIVE_ACTION = {
  Send: {
    title: "Send Questionnaire",
    body: "Are you sure, you want to Send the Questionnaire?"
  },
  Completed: {
    title: "Complete Questionnaire",
    body: "Are you sure, you want to Complete the Questionnaire?"
  },
  Reviewed: {
    title: "Review Questionnaire",
    body: "Are you sure, you want to Review the Questionnaire?"
  },
  Resend: {
    title: "Resend Questionnaire",
    body: "Are you sure, you want to Resend the Questionnaire?"
  }
}
export const KB_PERMISSION_STAGES = "Knowledge Base Manage Stages";
export const KB_PERMISSION_STEPS = "Knowledge Base Manage Steps";
export const KB_PERMISSION_DOC_CHECK_ITEMS = "Knowledge Base Manage Document Checklist Items";
export const KB_PERMISSION_DOC_CHECKLIST = "Knowledge Base Manage Document Checklist";
export const KB_PERMISSION_DOC_TYPE = "Knowledge Base Manage Document Type";
export const KB_PERMISSION_DOCUMENT = "Knowledge Base Manage Document";
export const KB_PERMISSION_FORMS = "Knowledge Base Manage Forms";
export const KB_PERMISSION_EMAIL_TEMP = "Knowledge Base Manage Email Templates";
export const KB_PERMISSION_QUESTION = "Knowledge Base Manage Questions";
export const KB_PERMISSION_QUESTIONNAIRE = "Knowledge Base Manage Questionnaires";
export const PERMISSION_RBAC = "You don't have permission to perform this action.";
export const CLIENT_MANAGEMENT_PERMISSION_LISTING = "Client Management Listing";
export const CLIENT_MANAGEMENT_PERMISSION_RELATIVES = "Client Management Relatives";
export const CLIENT_MANAGEMENT_PERMISSION_COMMUNICATION = "Client Management Communication";
export const CLIENT_MANAGEMENT_PERMISSION_NOTES = "Client Management Notes";
export const CLIENT_MANAGEMENT_PERMISSION_BILLING = "Client Management Billing";
export const CLIENT_MANAGEMENT_PERMISSION_CASE = "Client Management Case";
export const CLIENT_MANAGEMENT_PERMISSION_ALERTS = "Client Management Alerts";

export const EB2_NIW_FOLDERS = [{
  label: 'A. CH files (from Onboarding)',
  code: 'CH',
  items: [
    { label: 'Filing Fee', value: 'Filing Fee;A. CH files (from Onboarding)' },
    { label: 'From Onboarding', value: 'From Onboarding;A. CH files (from Onboarding)' },
    { label: 'Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files (from Onboarding)' }
  ]
},
{
  label: 'B. Translations',
  code: 'TR',
  items: [
    { label: '1st Batch', value: '1st Batch;B. Translations' },
    { label: '2nd Batch', value: '2nd Batch;B. Translations' },
  ]
},
{
  label: 'C. TOC, Binder and Checklist',
  code: 'TB',
  items: [
    { label: 'C. TOC, Binder and Checklist', value: 'C. TOC, Binder and Checklist;C. TOC, Binder and Checklist' }
  ]
},
{
  label: 'D. Forms',
  code: 'FR',
  items: [
    { label: 'D. Forms', value: 'D. Forms;D. Forms' }
  ]
},
{
  label: 'E. Civil documents',
  code: 'CD',
  items: [
    { label: 'Passports', value: 'Passports;E. Civil documents' },
    { label: 'Birth Certificates', value: 'Birth Certificates;E. Civil documents' },
    { label: 'Marriage Certificates', value: 'Marriage Certificates;E. Civil documents' }
  ]
},
{
  label: 'F. Degree (diplomas, academic evaluation)',
  code: 'AD',
  items: [
    { label: 'Academic evaluation', value: 'Academic evaluation;F. Degree (diplomas, academic evaluation)' },
    { label: 'Masters degree', value: 'Masters degree;F. Degree (diplomas, academic evaluation)' },
    { label: 'Bacherlors degree', value: 'Bacherlors degree;F. Degree (diplomas, academic evaluation)' }
  ]
},
{
  label: 'G. Endeavor Statement and Model - Plan',
  code: 'ES',
  items: [
    { label: 'Endeavor Statement (ES)', value: 'Endeavor Statement (ES);G. Endeavor Statement and Model - Plan' },
    { label: 'Model-Plan for future activities', value: 'Model-Plan for future activities;G. Endeavor Statement and Model - Plan' },

  ]
},
{
  label: 'H. Work Experience',
  code: 'WE',
  items: [
    { label: 'Curriculum Vitae (CV)', value: 'Curriculum Vitae (CV);H. Work Experience' },
    { label: 'Foreign Companies', value: 'Foreign Companies;H. Work Experience' },
    { label: 'Sample of Work Product', value: 'Sample of Work Product;H. Work Experience' },
    { label: 'Work Experience letter (WELs)', value: 'Work Experience letter (WELs);H. Work Experience' }
  ]
},
{
  label: 'I. Letters of Recommendation',
  code: 'LR',
  items: [
    { label: 'Drafts', value: 'Drafts' },
    { label: 'Final version - approved', value: 'Final version - approved;I. Letters of Recommendation' },
    { label: 'Questionnaires', value: 'Questionnaires;I. Letters of Recommendation' },
    { label: 'Recommenders CV or Linkedin', value: 'Recommenders CV or Linkedin;I. Letters of Recommendation' }
  ]
},
{
  label: 'J. Letters of Interest',
  code: 'LI',
  items: [
    { label: 'Drafts', value: 'Drafts;J. Letters of Interest' },
    { label: 'Final version - approved', value: 'Final version - approved;J. Letters of Interest' },
    { label: 'Questionnaires', value: 'Questionnaires;J. Letters of Interest' },
  ]
},
{
  label: 'K. Well- Positioned',
  code: 'WP',
  items: [
    { label: 'Certificates', value: 'Certificates;K. Well- Positioned' },
    { label: 'Licenses', value: 'Licenses;K. Well- Positioned' },
    { label: 'Memberships', value: 'Memberships;K. Well- Positioned' },
    { label: 'Publications and Patents', value: 'Publications and Patents;K. Well- Positioned' },
    { label: 'Awards and recognitions', value: 'Awards and recognitions;K. Well- Positioned' },
    { label: 'Continuing education', value: 'Continuing education;K. Well- Positioned' },
    { label: 'Media reports', value: 'Media reports;K. Well- Positioned' },
    { label: 'Other', value: 'Other;K. Well- Positioned' },
  ]
},

{
  label: 'L. Writers',
  code: 'WR',
  items: [
    { label: '1.Substantial Merit', value: '1.Substantial Merit;L. Writers' },
    { label: '2.National Importance', value: '2.National Importance;L. Writers' },
    { label: '3. On Balance Prong 3', value: '3. On Balance Prong 3;L. Writers' },
    { label: '4. Brief', value: '4. Brief' }
  ]
},
{
  label: 'M. Others',
  code: 'OTH',
  items: [
    { label: 'M. Others', value: 'M. Others;M. Others' }
  ]
},
{
  label: 'Client Uploaded Documents',
  code: 'CUD',
  items: [
    { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
  ]
},
];

export const AOS_FOLDERS = [
  {
    label: 'A. Civil Documents',
    code: 'AOSCD',
    items: [
      { label: 'Passports', value: 'Passports;A. Civil Documents' },
      { label: 'Birth certificates', value: 'Birth certificates;A. Civil Documents' },
      { label: 'Marriage certificate', value: 'Marriage certificate;A. Civil Documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;A. Civil Documents' },
    ]
  },
  {
    label: 'B. Translations',
    code: 'AOSTR',
    items: [
      { label: 'B. Translations', value: 'B. Translations;B. Translations' }
    ]
  },
  {
    label: 'C. USCIS submissions (AOS)',
    code: 'US',
    items: [
      { label: 'C. USCIS submissions (AOS)', value: 'C. USCIS submissions (AOS);C. USCIS submissions (AOS)' }
    ]
  },
  {
    label: 'D. Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)',
    code: 'NF',
    items: [
      { label: 'D. Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)', value: 'D. Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial);D. Notices from USCIS (Receipt, Biometrics, Approval, RFE, Denial)' }
    ]
  },
  {
    label: 'E. Inquiries to USCIS',
    code: 'IN',
    items: [
      { label: 'E. Inquiries to USCIS', value: 'E. Inquiries to USCIS;E. Inquiries to USCIS' }
    ]
  },
  {
    label: 'F. Inquiries to Ombudsman',
    code: 'IO',
    items: [
      { label: 'F. Inquiries to Ombudsman', value: 'F. Inquiries to Ombudsman;F. Inquiries to Ombudsman' }
    ]
  },
  {
    label: 'G. Change of Address',
    code: 'CA',
    items: [
      { label: 'G. Change of Address', value: 'G. Change of Address;G. Change of Address' }
    ]
  },
  {
    label: 'H. Documents received by USCIS (I512, EAD, Green Card)',
    code: 'DR',
    items: [
      { label: 'H. Documents received by USCIS (I512, EAD, Green Card)', value: 'H. Documents received by USCIS (I512, EAD, Green Card);H. Documents received by USCIS (I512, EAD, Green Card)' }
    ]
  },
  {
    label: 'I. Others',
    code: 'Ot',
    items: [
      { label: 'I. Others', value: 'I. Others;I. Others' }
    ]
  },
  {
    label: 'Client Uploaded Documents',
    code: 'CUD',
    items: [
      { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
    ]
  },
];

export const CP_Folders = [
  {
    label: 'A. Translations',
    code: 'CPTR',
    items: [
      { label: 'A. Translations', value: 'A. Translations;A. Translations' },
    ]
  },
  {
    label: 'B. Case Number and Invoice',
    code: 'CPCN',
    items: [
      { label: 'B. Case Number and Invoice', value: 'B. Case Number and Invoice;B. Case Number and Invoice' },
    ]
  },
  {
    label: 'C. Payment receipt',
    code: 'CPPR',
    items: [
      { label: 'C. Payment receipt', value: 'C. Payment receipt;C. Payment receipt' },
    ]
  },
  {
    label: 'D. DS-260 Forms',
    code: 'CPDS',
    items: [
      { label: 'D. DS-260 Forms', value: 'D. DS-260 Forms;D. DS-260 Forms' },
    ]
  },
  {
    label: 'E. DS-260 Confirmation Pages',
    code: 'CPDS260',
    items: [
      { label: 'E. DS-260 Confirmation Pages', value: 'E. DS-260 Confirmation Pages;E. DS-260 Confirmation Pages' },
    ]
  },
  {
    label: 'F. DS-1810 (if applies)',
    code: 'CPDS1810',
    items: [
      { label: 'F. DS-1810 (if applies)', value: 'F. DS-1810 (if applies);F. DS-1810 (if applies)' },
    ]
  },
  {
    label: 'G. NVC Packages',
    code: 'CPNVC',
    items: [
      { label: 'G. NVC Packages', value: 'G. NVC Packages;G. NVC Packages' },
    ]
  },
  {
    label: 'H. Documentarily Complete',
    code: 'CPDOC',
    items: [
      { label: 'H. Documentarily Complete', value: 'H. Documentarily Complete;H. Documentarily Complete' },
    ]
  },
  {
    label: 'I. RFE and RFE response',
    code: 'CPRFE',
    items: [
      { label: 'I. RFE and RFE response', value: 'I. RFE and RFE response;I. RFE and RFE response' },
    ]
  },
  {
    label: 'J. Inquiries to NVC',
    code: 'CPJIQ',
    items: [
      { label: 'J. Inquiries to NVC', value: 'J. Inquiries to NVC;J. Inquiries to NVC' },
    ]
  },
  {
    label: 'K. Notices from NVC',
    code: 'CPNOT',
    items: [
      { label: 'K. Notices from NVC', value: 'K. Notices from NVC;K. Notices from NVC' },
    ]
  },
  {
    label: 'L. IV Appointment',
    code: 'CPIV',
    items: [
      { label: 'L. IV Appointment', value: 'L. IV Appointment;L. IV Appointment' },
    ]
  },
  {
    label: 'N. Others',
    code: 'CPOTH',
    items: [
      { label: 'N. Others', value: 'N. Others;N. Others' },
    ]
  },
  {
    label: 'Client Uploaded Documents',
    code: 'CPCPD',
    items: [
      { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' },
    ]
  },

];

export const EB5 = [
  {
    label: 'A. Civil documents',
    code: 'CD',
    items: [
      { label: 'Passports', value: 'Passports;A. Civil documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;A. Civil documents' },
      { label: 'Marriage Certificates', value: 'Marriage Certificates;A. Civil documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;A. Civil documents' }
    ]
  },
  {
    label: 'B. CV',
    code: 'TR',
    items: [
      { label: 'B. CV', value: 'B. CV;B. CV' }
    ]
  },
  {
    label: 'C. Cover letter and Binder',
    code: 'TR',
    items: [
      { label: 'C. Cover letter and Binder', value: 'C. Cover letter and Binder;C. Cover letter and Binder' }
    ]
  },
  {
    label: 'D. Translations',
    code: 'TR',
    items: [
      { label: 'D. Translations', value: 'D. Translations;D. Translations' }
    ]
  },
  {
    label: 'E. Statement of the origin of funds',
    code: 'TR',
    items: [
      { label: 'E. Statement of the origin of funds', value: 'E. Statement of the origin of funds;E. Statement of the origin of funds' }
    ]
  },
  {
    label: 'F. Educational diplomas, certificates, licenses',
    code: 'TR',
    items: [
      { label: 'F. Educational diplomas, certificates, licenses', value: 'F. Educational diplomas, certificates, licenses;F. Educational diplomas, certificates, licenses' }
    ]
  },
  {
    label: 'G. Taxes (most recent 5 years)',
    code: 'TR',
    items: [
      { label: 'G. Taxes (most recent 5 years)', value: 'G. Taxes (most recent 5 years);G. Taxes (most recent 5 years)' }
    ]
  },
  {
    label: 'H. Capital earned through ownership of business',
    code: 'TR',
    items: [
      { label: 'Business registration', value: 'Business registration;H. Capital earned through ownership of business' },
      { label: 'Company license', value: 'Company licenseH. Capital earned through ownership of business' },
      { label: 'Business premises and printed material', value: 'Business premises and printed material;H. Capital earned through ownership of business' },
      { label: 'Partnership agreement, share register', value: 'Partnership agreement, share register;H. Capital earned through ownership of business' },
      { label: 'SOF to purchase shares', value: 'SOF to purchase shares;H. Capital earned through ownership of business' },
      { label: 'Distribution of dividends', value: 'Distribution of dividends;H. Capital earned through ownership of business' },
      { label: 'Financial statements', value: 'Financial statements;H. Capital earned through ownership of business' },
      { label: 'Business taxes (last 5 years)', value: 'Business taxes (last 5 years);H. Capital earned through ownership of business' },
    ]
  },
  {
    label: 'I. Capital earned through property sale',
    code: 'TR',
    items: [
      { label: 'Property purchase contract', value: 'Property purchase contract;I. Capital earned through property sale' },
      { label: 'SOF to purchase the propery', value: 'SOF to purchase the propery;I. Capital earned through property sale' },
      { label: 'Loan and mortgage documents', value: 'Loan and mortgage documents;I. Capital earned through property sale' },
      { label: 'Payment of purchase price', value: 'Payment of purchase price;I. Capital earned through property sale' },
      { label: 'Property ownership registration', value: 'Property ownership registration;I. Capital earned through property sale' },
      { label: 'Property sales contract', value: 'Property sales contract;I. Capital earned through property sale' },
      { label: 'Receipt of purchase price', value: 'Receipt of purchase price;I. Capital earned through property sale' },
      { label: 'Real estate tax certificate', value: 'Real estate tax certificate;I. Capital earned through property sale' }
    ]
  },
  {
    label: 'J. Capital earned through gift',
    code: 'TR',
    items: [
      { label: 'Donor statement of gift', value: 'Donor statement of gift;J. Capital earned through gift' },
      { label: 'Payment of gift taxes', value: 'Payment of gift taxes;J. Capital earned through gift' },
      { label: 'Donor/donee relationship', value: 'Donor/donee relationship;J. Capital earned through gift' },
      { label: 'Donors Civil Documents', value: 'Donors Civil Documents;J. Capital earned through gift' },
      { label: 'Donor education and job background', value: 'Donor education and job background;J. Capital earned through gift' },
      { label: 'SOF to gift amount', value: 'SOF to gift amount;J. Capital earned through gift' },
      { label: 'Transfer of funds donor/donee', value: 'Transfer of funds donor/donee;J. Capital earned through gift' },
      { label: 'Donor taxes (last 5 years)', value: 'Donor taxes (last 5 years);J. Capital earned through gift' }
    ]
  },
  {
    label: 'K. Capital earned through loan',
    code: 'TR',
    items: [
      { label: 'Loan agreement', value: 'Loan agreement;K. Capital earned through loan' },
      { label: 'Receipt of loan capital', value: 'Receipt of loan capital;K. Capital earned through loan' },
    ]
  },
  {
    label: 'L. Capital earned through employment earnings',
    code: 'TR',
    items: [
      { label: 'Employment evidence', value: 'Employment evidence;L. Capital earned through employment earnings' },
      { label: 'Employer evidence', value: 'Employer evidence;L. Capital earned through employment earnings' },
      { label: 'Payroll records', value: 'Payroll records;L. Capital earned through employment earnings' },
      { label: 'Bank statements of savings', value: 'Bank statements of savings;L. Capital earned through employment earnings' }
    ]
  },
  {
    label: 'M. Capital earned through inheritance',
    code: 'TR',
    items: [
      { label: 'Will and testament', value: 'Will and testament;M. Capital earned through inheritance' },
      { label: 'Benefactor/Beneficiray relationship', value: 'Benefactor/Beneficiray relationship;M. Capital earned through inheritance' },
      { label: 'Inheritance tax payment', value: 'Inheritance tax payment;M. Capital earned through inheritance' },
      { label: 'SOF to acquired inherited funds', value: 'SOF to acquired inherited funds;M. Capital earned through inheritance' },
      { label: 'Receipt of inherited funds', value: 'Receipt of inherited funds;M. Capital earned through inheritance' }
    ]
  },
  {
    label: 'N. Other SOF',
    code: 'TR',
    items: [
      { label: 'N. Other SOF', value: 'N. Other SOF;N. Other SOF' }
    ]
  },
  {
    label: 'O. Transfer of funds to the U.S. enterprise ',
    code: 'TR',
    items: [
      { label: 'Bank statements showing capital', value: 'Bank statements showing capital;O. Transfer of funds to the U.S. enterprise' },
      { label: 'Bank transfers, wire receipts to the US', value: 'Bank transfers, wire receipts to the US;O. Transfer of funds to the U.S. enterprise' }
    ]
  },
  {
    label: 'P. Filing fee',
    code: 'FF',
    items: [
      { label: 'P. Filing fee', value: 'P. Filing fee;P. Filing fee' }
    ]
  },
  {
    label: 'Q. Checklist for Paralegals',
    code: 'CFP',
    items: [
      { label: 'Q. Checklist for Paralegals', value: 'Q. Checklist for Paralegals;Q. Checklist for Paralegals' }
    ]
  },
  {
    label: 'R. Notices from USCIS (Receipt, Approval, RFE, Denial)',
    code: 'NUSC',
    items: [
      { label: 'R. Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'R. Notices from USCIS (Receipt, Approval, RFE, Denial);R. Notices from USCIS (Receipt, Approval, RFE, Denial)' }
    ]
  },
  {
    label: 'S. Others',
    code: 'SOTH',
    items: [
      { label: 'S. Others', value: 'S. Others;S. Others' }
    ]
  },
  {
    label: 'Client Uploaded Documents',
    code: 'CUD',
    items: [
      { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
    ]
  },
];

export const MANDAMUS = [
  {
    label: 'A. Filings and Court documents',
    code: 'MAFCD',
    items: [
      { label: 'A. Filings and Court documents', value: 'A. Filings and Court documents;A. Filings and Court documents' }
    ]
  },
  {
    label: 'B. AOS',
    code: 'MAAOS',
    items: [
      { label: 'B. AOS', value: 'B. AOS;B. AOS' }
    ]
  },
  {
    label: 'C. I-526',
    code: 'MAI526',
    items: [
      { label: 'C. I-526', value: 'C. I-526;C. I-526' }
    ]
  },
  {
    label: 'D. I-829',
    code: 'MAI829',
    items: [
      { label: 'D. I-829', value: 'D. I-829;D. I-829' }
    ]
  },
  {
    label: 'E. Others',
    code: 'MAOTH',
    items: [
      { label: 'E. Others', value: 'E. Others;E. Others' }
    ]
  },
  {
    label: 'Client Uploaded Documents',
    code: 'CUD',
    items: [
      { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
    ]
  },
];

export const E2 = [{
  label: 'A. Cover letter, TOC, and Binder',
  code: 'E2CL',
  items: [
    { label: 'A. Cover letter, TOC, and Binder', value: 'A. Cover letter, TOC, and Binder;A. Cover letter, TOC, and Binder' }
  ]
},
{
  label: 'B. Civil documents',
  code: 'E2CL',
  items: [
    { label: 'Passports', value: 'Passports;B. Civil documents' },
    { label: 'Visas', value: 'Visas;B. Civil documents' },
    { label: 'I-94', value: 'I-94;B. Civil documents' },
    { label: 'Marriage Certificate', value: 'Marriage Certificate;B. Civil documents' },
    { label: 'Birth Certificates', value: 'Birth Certificates;B. Civil documents' },
    { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;B. Civil documents' },
  ]
},
{
  label: 'C. Declaration of Intent to Depart the U.S.',
  code: 'E2DIDUS',
  items: [
    { label: 'C. Declaration of Intent to Depart the U.S.', value: 'C. Declaration of Intent to Depart the U.S.;C. Declaration of Intent to Depart the U.S.' }
  ]
},
{
  label: 'D. Source of Funds',
  code: 'E2SF',
  items: [
    { label: 'D. Source of Funds', value: 'D. Source of Funds;D. Source of Funds' }
  ]
},
{
  label: 'E. Ownership documents',
  code: 'E2OD',
  items: [
    { label: 'E. Ownership documents', value: 'E. Ownership documents;E. Ownership documents' }
  ]
},
{
  label: 'F. Investment of Funds (Wire transfers, purchase agreement, etc)',
  code: 'E2IF',
  items: [
    { label: 'F. Investment of Funds (Wire transfers, purchase agreement, etc)', value: 'F. Investment of Funds (Wire transfers, purchase agreement, etc);F. Investment of Funds (Wire transfers, purchase agreement, etc)' }
  ]
},
{
  label: 'G. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)',
  code: 'E2RO',
  items: [
    { label: 'G. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)', value: 'G. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc);G. Real and Operating (Lease, Licenses, Bank Statements, Invoices, etc)' }
  ]
},
{
  label: 'H. Marginality (Business Plan, Balance Sheet, PL, Taxes, W-2, etc)',
  code: 'E2MAR',
  items: [
    { label: 'H. Marginality (Business Plan, Balance Sheet, PL, Taxes, W-2, etc)', value: 'H. Marginality (Business Plan, Balance Sheet, PL, Taxes, W-2, etc);H. Marginality (Business Plan, Balance Sheet, PL, Taxes, W-2, etc)' }
  ]
},
{
  label: 'I. CV',
  code: 'E2CV',
  items: [
    { label: 'I. CV', value: 'I. CV;I. CV' }
  ]
},
{
  label: 'J. Educational diplomas, certificates, licenses',
  code: 'E2EDCL',
  items: [
    { label: 'J. Educational diplomas, certificates, licenses', value: 'J. Educational diplomas, certificates, licenses;J. Educational diplomas, certificates, licenses' }
  ]
},
{
  label: 'K. DOS Forms (DS-160, DS-156E, Confirmation page)',
  code: 'E2DOS',
  items: [
    { label: 'K. DOS Forms (DS-160, DS-156E, Confirmation page)', value: 'K. DOS Forms (DS-160, DS-156E, Confirmation page);K. DOS Forms (DS-160, DS-156E, Confirmation page)' }
  ]
},
{
  label: 'L. USCIS Forms (I-129, I-129E, I-907, I-539, I-539A)',
  code: 'E2USCIS',
  items: [
    { label: 'L. USCIS Forms (I-129, I-129E, I-907, I-539, I-539A)', value: 'L. USCIS Forms (I-129, I-129E, I-907, I-539, I-539A);L. USCIS Forms (I-129, I-129E, I-907, I-539, I-539A)' }
  ]
},
{
  label: 'M. Pictures',
  code: 'E2PIC',
  items: [
    { label: 'M. Pictures', value: 'M. Pictures;M. Pictures' }
  ]
},
{
  label: 'N. Filing fee',
  code: 'E2FF',
  items: [
    { label: 'N. Filing fee', value: 'N. Filing fee;N. Filing fee' }
  ]
},
{
  label: 'O. Checklist for Paralegals',
  code: 'E2CHP',
  items: [
    { label: 'O. Checklist for Paralegals', value: 'O. Checklist for Paralegals;O. Checklist for Paralegals' }
  ]
},
{
  label: 'P. Notices from USCIS (Receipt, Approval, RFE, Denial)',
  code: 'E2NO',
  items: [
    { label: 'P. Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'P. Notices from USCIS (Receipt, Approval, RFE, Denial);P. Notices from USCIS (Receipt, Approval, RFE, Denial)' }
  ]
},
{
  label: 'Q. RFE responses',
  code: 'E2RFE',
  items: [
    { label: 'Q. RFE responses', value: 'Q. RFE responses;Q. RFE responses' }
  ]
},
{
  label: 'R. Consular Interview and documents',
  code: 'E2COUN',
  items: [
    { label: 'R. Consular Interview and documents', value: 'R. Consular Interview and documents;R. Consular Interview and documents' }
  ]
},
{
  label: 'S. E-2 Visas',
  code: 'E2VIS',
  items: [
    { label: 'S. E-2 Visas', value: 'S. E-2 Visas;S. E-2 Visas' }
  ]
},
{
  label: 'T. Others',
  code: 'E2OTH',
  items: [
    { label: 'T. Others', value: 'T. Others;T. Others' }
  ]
},
{
  label: 'Client Uploaded Documents',
  code: 'CUD',
  items: [
    { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
  ]
},

];

export const EB1 = [{
  label: '1. CH files (from Onboarding)',
  code: 'EB1CH',
  items: [
    { label: 'Filing Fee', value: 'Filing Fee;1. CH files (from Onboarding)' },
    { label: 'From Onboarding', value: 'From Onboarding1. CH files (from Onboarding)' },
    { label: 'Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'Notices from USCIS (Receipt, Approval, RFE, Denial);1. CH files (from Onboarding)' },
  ]
},
{
  label: '2. Translations',
  code: 'EB1TR',
  items: [
    { label: '1st Batch', value: '2nd Batch;2. Translations' }
  ]
},
{
  label: '3. TOC and Binder',
  code: 'EB1TOC',
  items: [
    { label: '3. TOC and Binder', value: '3. TOC and Binder;3. TOC and Binder' }
  ]
},
{
  label: 'A. Forms',
  code: 'EB1FORMS',
  items: [
    { label: 'A. Forms', value: 'A. Forms;A. Forms' }
  ]
},
{
  label: 'B. Civil documents',
  code: 'EB1CIVIL',
  items: [
    { label: 'Passports, visas and I-94s', value: 'Passports, visas and I-94s;B. Civil documents;B. Civil documents' },
    { label: 'Marriage Certificate', value: 'Marriage Certificate;B. Civil documents' },
    { label: 'Birth Certificates', value: 'Birth Certificates;B. Civil documents' },
    { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;B. Civil documents' },
  ]
},
{
  label: 'C. Advanced degree (diplomas, academic evaluation)',
  code: 'EB1AD',
  items: [
    { label: 'Academic evaluation', value: 'Academic evaluation;C. Advanced degree (diplomas, academic evaluation)' },
    { label: 'Masters degree', value: 'Masters degree;C. Advanced degree (diplomas, academic evaluation)' },
    { label: 'Bacherlors degree', value: 'Bacherlors degree;C. Advanced degree (diplomas, academic evaluation)' }
  ]
},
{
  label: 'D. CV',
  code: 'EB1CV',
  items: [
    { label: 'Endeavor Statement (ES)', value: 'Endeavor Statement (ES);D. CV' },
    { label: 'Model-Plan for future activities', value: 'Model-Plan for future activities;D. CV' },

  ]
},
{
  label: 'E. Work Experience Letters',
  code: 'EB1WORK',
  items: [
    { label: 'Curriculum Vitae (CV)', value: 'Curriculum Vitae (CV);E. Work Experience Letters' },
    { label: 'Foreign Companies', value: 'Foreign Companies;E. Work Experience Letters' },
    { label: 'Sample of Work Product', value: 'Sample of Work Product;E. Work Experience Letters' },
    { label: 'Work Experience letter (WELs)', value: 'Work Experience letter (WELs);E. Work Experience Letters' }
  ]
},
{
  label: 'F. Prizes or Awards',
  code: 'EB1PRIZE',
  items: [
    { label: 'Drafts', value: 'Drafts' },
    { label: 'Final version - approved', value: 'Final version - approved;F. Prizes or Awards' },
    { label: 'Questionnaires', value: 'Questionnaires;F. Prizes or Awards' },
    { label: 'Recommenders CV or Linkedin', value: 'Recommenders CV or Linkedin;F. Prizes or Awards' }
  ]
},
{
  label: 'G. Memberships',
  code: 'EB1MEM',
  items: [
    { label: 'Drafts', value: 'Drafts;G. Memberships' },
    { label: 'Final version - approved', value: 'Final version - approved:;G. Memberships' },
    { label: 'Questionnaires', value: 'Questionnaires;G. Memberships' },
  ]
},
{
  label: 'H. Published Material and Citations',
  code: 'EB1PUB',
  items: [
    { label: 'Certificates', value: 'Certificates;H. Published Material and Citations' },
    { label: 'Licenses', value: 'Licenses;H. Published Material and Citations' },
    { label: 'Memberships', value: 'Memberships;H. Published Material and Citations' },
    { label: 'Publications and Patents', value: 'Publications and Patents;H. Published Material and Citations' },
    { label: 'Awards and recognitions', value: 'Awards and recognitions;H. Published Material and Citations' },
    { label: 'Continuing education', value: 'Continuing education;H. Published Material and Citations' },
    { label: 'Media reports', value: 'Media reports;H. Published Material and Citations' },
    { label: 'Other', value: 'Other;H. Published Material and Citations' },
  ]
},
{
  label: 'I. Media Publications about the Applicant',
  code: 'EB1MEDIA',
  items: [
    { label: '1.Substantial Merit', value: '1.Substantial Merit;I. Media Publications about the Applicant' },
    { label: '2.National Importance', value: '2.National Importance;I. Media Publications about the Applicant' },
    { label: '3. On Balance Prong 3', value: '3. On Balance Prong 3;I. Media Publications about the Applicant' },
    { label: '4. Brief', value: '4. Brief;I. Media Publications about the Applicant' }
  ]
},
{
  label: 'J. Invited talks, review, and judge of others',
  code: 'EB1INVITE',
  items: [
    { label: 'J. Invited talks, review, and judge of others', value: 'J. Invited talks, review, and judge of others;J. Invited talks, review, and judge of others' }
  ]
},
{
  label: 'K. Major contributions to the field',
  code: 'EB1MAJOR',
  items: [
    { label: 'K. Major contributions to the field', value: 'K. Major contributions to the field;K. Major contributions to the field' }
  ]
},
{
  label: 'L. Artistic Exhibitions or Showcases',
  code: 'EB1ART',
  items: [
    { label: 'L. Artistic Exhibitions or Showcases', value: 'L. Artistic Exhibitions or Showcases;L. Artistic Exhibitions or Showcases' }
  ]
},
{
  label: 'M. Leading or Critical Role',
  code: 'EB1LEAD',
  items: [
    { label: 'M. Leading or Critical Role', value: 'M. Leading or Critical Role;M. Leading or Critical Role' }
  ]
},
{
  label: 'N. High Salary',
  code: 'EB1HIGH',
  items: [
    { label: 'N. High Salary', value: 'N. High Salary;N. High Salary' }
  ]
},
{
  label: 'O. Commercial Successes in the Performing Arts',
  code: 'EB1COMM',
  items: [
    { label: 'O. Commercial Successes in the Performing Arts', value: 'O. Commercial Successes in the Performing Arts;O. Commercial Successes in the Performing Arts' }
  ]
},
{
  label: 'P. Job Offer in the U.S. (for EB-1B)',
  code: 'EB1JOB',
  items: [
    { label: 'P. Job Offer in the U.S. (for EB-1B)', value: 'P. Job Offer in the U.S. (for EB-1B);P. Job Offer in the U.S. (for EB-1B)' }
  ]
},
{
  label: 'Q. Filing fee',
  code: 'EB1FILING',
  items: [
    { label: 'Q. Filing fee', value: 'Q. Filing fee;Q. Filing fee' }
  ]
},
{
  label: 'R. Checklist for Paralegals',
  code: 'EB1CHECK',
  items: [
    { label: 'R. Checklist for Paralegals', value: 'R. Checklist for Paralegals;R. Checklist for Paralegals' }
  ]
},
{
  label: 'S. Notices from USCIS (Receipt, Approval, RFE, Denial)',
  code: 'EB1NOT',
  items: [
    { label: 'S. Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'S. Notices from USCIS (Receipt, Approval, RFE, Denial);S. Notices from USCIS (Receipt, Approval, RFE, Denial)' }
  ]
},
{
  label: 'T. Others',
  code: 'EB1OTH',
  items: [
    { label: 'T. Others', value: 'T. Others;T. Others' }
  ]
},
{
  label: 'Client Uploaded Documents',
  code: 'CUD',
  items: [
    { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
  ]
},
];

export const EB1A = [{
  label: 'A. CH files',
  code: 'EB1ACH',
  items: [
    { label: 'Filing Fee', value: 'Filing Fee;A. CH files' },
    { label: 'From Onboarding', value: 'From Onboarding;A. CH files' },
    { label: 'Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files' }

  ]
},
{
  label: 'B. Translations',
  code: 'EB1ATRA',
  items: [
    { label: '1st Batch', value: '1st Batch;B. Translations' },
    { label: '2nd Batch', value: '2nd Batch;B. Translations' },
  ]
},
{
  label: 'C. TOC, Binder and Checklist',
  code: 'EB1ATOC',
  items: [
    { label: 'C. TOC, Binder and Checklist', value: 'C. TOC, Binder and Checklist;C. TOC, Binder and Checklist' }
  ]
},
{
  label: 'D. Forms',
  code: 'EB1AFR',
  items: [
    { label: 'D. Forms', value: 'D. Forms;D. Forms' }
  ]
},
{
  label: 'E. Civil documents',
  code: 'EB1ACIVIL',
  items: [
    { label: 'Passports, visas and I-94s', value: 'Passports, visas and I-94s;E. Civil documents' },
    { label: 'Birth Certificates', value: 'Birth Certificates;E. Civil documents' },
    { label: 'Marriage Certificates', value: 'Marriage Certificates;E. Civil documents' },
    { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;E. Civil documents' }
  ]
},
{
  label: 'F. Degrees (diplomas, academic evaluation)',
  code: 'EB1ADEGREE',
  items: [
    { label: 'Academic evaluation', value: 'Academic evaluation;F. Degrees (diplomas, academic evaluation)' },
    { label: 'Masters degree', value: 'Masters degree;F. Degrees (diplomas, academic evaluation)' },
    { label: 'Bacherlors degree', value: 'Bacherlors degree;F. Degrees (diplomas, academic evaluation)' }
  ]
},
{
  label: 'G. Work Experience',
  code: 'EB1AWORK',
  items: [
    { label: 'CV', value: 'CV;G. Work Experience' },
    { label: 'Work Experience Letters (WELs)', value: 'Work Experience Letters (WELs);G. Work Experience' },
    { label: 'Other', value: 'Other;G. Work Experience' },
  ]
},
{
  label: 'H. Extraordinary Ability',
  code: 'EB1AEXTRA',
  items: [
    { label: 'A. Prizes or Awards', value: 'A. Prizes or Awards;H. Extraordinary Ability' },
    { label: 'B. Memberships', value: 'B. Memberships;H. Extraordinary Ability' },
    { label: 'C. Media and Publication Recognition', value: 'C. Media and Publication Recognition;H. Extraordinary Ability' },
    { label: 'D. Invited Talks, Review and Judging Record', value: 'D. Invited Talks, Review and Judging Record;H. Extraordinary Ability' },
    { label: 'E. Major contribution to the field', value: 'E. Major contribution to the field;H. Extraordinary Ability' },
    { label: 'F. Published Material Citations', value: 'F. Published Material Citations;H. Extraordinary Ability' },
    { label: 'G. Artistic Exhibitions or Showcases', value: 'G. Artistic Exhibitions or Showcases;H. Extraordinary Ability' },
    { label: 'H. Performance in a Leading or Major Role', value: 'H. Performance in a Leading or Major Role;H. Extraordinary Ability' },
    { label: 'I. High Remuneration or Commercial Succeses', value: 'I. High Remuneration or Commercial Succeses;H. Extraordinary Ability' },
  ]
},
{
  label: 'I. Writers',
  code: 'EB1AWRIT',
  items: [
    { label: '1.Substantial Merit', value: '1.Substantial Merit;I. Writers' },
    { label: '2.National Importance', value: '2.National Importance;I. Writers' },
    { label: '3. On Balance Prong 3', value: '3. On Balance Prong 3;I. Writers' },
    { label: '4. Brief', value: '4. Brief;I. Writers' },
  ]
},
{
  label: 'J. Other',
  code: 'EB1AOTH',
  items: [
    { label: 'Letters of Recommendation', value: 'Letters of Recommendation;J. Other' },
  ]
},
{
  label: 'Client Uploaded Documents',
  code: 'CUD',
  items: [
    { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
  ]
},
]

export const EB1B = [
  {
    label: 'A. CH files',
    code: 'EB1BCH',
    items: [
      { label: 'Filing Fee', value: 'Filing Fee;A. CH files' },
      { label: 'From Onboarding', value: 'From Onboarding;A. CH files' },
      { label: 'Notices from USCIS (Receipt, Approval, RFE, Denial)', value: 'Notices from USCIS (Receipt, Approval, RFE, Denial);A. CH files' }

    ]
  },
  {
    label: 'B. Translations',
    code: 'EB1BTRA',
    items: [
      { label: '1st Batch', value: '1st Batch;B. Translations' },
      { label: '2nd Batch', value: '2nd Batch;B. Translations' },
    ]
  },
  {
    label: 'C. TOC, Binder and Checklist',
    code: 'EB1BTOC',
    items: [
      { label: 'C. TOC, Binder and Checklist', value: 'C. TOC, Binder and Checklist;C. TOC, Binder and Checklist' }
    ]
  },
  {
    label: 'D. Forms',
    code: 'EB1BFR',
    items: [
      { label: 'D. Forms', value: 'D. Forms;D. Forms' }
    ]
  },
  {
    label: 'E. Civil documents',
    code: 'EB1BCIVIL',
    items: [
      { label: 'Passports, visas and I-94s', value: 'Passports, visas and I-94s;E. Civil documents' },
      { label: 'Birth Certificates', value: 'Birth Certificates;E. Civil documents' },
      { label: 'Marriage Certificates', value: 'Marriage Certificates;E. Civil documents' },
      { label: 'Termination of Prior Marriages', value: 'Termination of Prior Marriages;E. Civil documents' }
    ]
  },
  {
    label: 'F. Degrees (diplomas, academic evaluation)',
    code: 'EB1BDEGREE',
    items: [
      { label: 'Academic evaluation', value: 'Academic evaluation;F. Degrees (diplomas, academic evaluation)' },
      { label: 'Masters degree', value: 'Masters degree;F. Degrees (diplomas, academic evaluation)' },
      { label: 'Bacherlors degree', value: 'Bacherlors degree;F. Degrees (diplomas, academic evaluation)' }
    ]
  },
  {
    label: 'G. Work Experience',
    code: 'EB1BWORK',
    items: [
      { label: 'CV', value: 'CV;G. Work Experience' },
      { label: 'Work Experience Letters (WELs)', value: 'Work Experience Letters (WELs);G. Work Experience' },
      { label: 'Other', value: 'Other;G. Work Experience' },
    ]
  },
  {
    label: 'H. Job Offer in the U.S.',
    code: 'EB1BJOB',
    items: [
      { label: 'H. Job Offer in the U.S.', value: 'H. Job Offer in the U.S.;H. Job Offer in the U.S.' }
    ]
  },

  {
    label: 'I. Outstanding Researcher or Proffesor',
    code: 'EB1BEXTRA',
    items: [
      { label: 'A. Prizes or Awards', value: 'A. Prizes or Awards;I. Outstanding Researcher or Proffesor' },
      { label: 'B. Memberships', value: 'B. Memberships;I. Outstanding Researcher or Proffesor' },
      { label: 'C. Media and Publication Recognition', value: 'C. Media and Publication Recognition;I. Outstanding Researcher or Proffesor' },
      { label: 'D. Invited Talks, Review and Judging Record', value: 'D. Invited Talks, Review and Judging Record;I. Outstanding Researcher or Proffesor' },
      { label: 'E. Major contribution to the field', value: 'E. Major contribution to the field;I. Outstanding Researcher or Proffesor' },
      { label: 'F. Published Material Citations', value: 'F. Published Material Citations;I. Outstanding Researcher or Proffesor' },
      { label: 'G. Artistic Exhibitions or Showcases', value: 'G. Artistic Exhibitions or Showcases;I. Outstanding Researcher or Proffesor' },
      { label: 'H. Performance in a Leading or Major Role', value: 'H. Performance in a Leading or Major Role;I. Outstanding Researcher or Proffesor' },
      { label: 'I. High Remuneration or Commercial Succeses', value: 'I. High Remuneration or Commercial Succeses;I. Outstanding Researcher or Proffesor' },
    ]
  },
  {
    label: 'J. Witers',
    code: 'EB1BWRIT',
    items: [
      { label: '1.Substantial Merit', value: '1.Substantial Merit;J. Witers' },
      { label: '2.National Importance', value: '2.National Importance;J. Witers' },
      { label: '3. On Balance Prong 3', value: '3. On Balance Prong 3;J. Witers' },
      { label: '4. Brief', value: '4. Brief;J. Witers' },
    ]
  },
  {
    label: 'K. Other',
    code: 'EB1BOTH',
    items: [
      { label: 'Letters of Recommendation', value: 'Letters of Recommendation;K. Other' },
    ]
  },
  {
    label: 'Client Uploaded Documents',
    code: 'CUD',
    items: [
      { label: 'Client Uploaded Documents', value: 'Client Uploaded Documents;Client Uploaded Documents' }
    ]
  },
]

export const APPLICANT_NOT_SELECTED = "Please select atleast one applicant.";
export const DOC_CHK_LIST_NOT_SELECTED = "Please select atleast one document checklist item.";
export const UNSAVED_CHANGES_DOC_CHKLIST = "There are unsaved changes in selected checklist items. Please ensure you made required changes before clicking on submit button.";

export const CASE_FOLDER_STRUCTURE = [
  { label: "AOS", value: "AOS" },
  { label: "CP", value: "CP" },
  { label: "EB-2 NIW", value: "EB-2 NIW" },
  { label: "EB-5", value: "EB-5" },
  { label: "Writ of Mandamus", value: "Writ of Mandamus" },
  { label: "E-2", value: "E-2" },
  { label: "EB-1", value: "EB-1" },
  { label: "EB-1A", value: "EB-1A" },
  { label: "EB-1B", value: "EB-1B" }
];

export const CASE_DOCUMENT_ACTION = {
  Requested: {
    title: "Request Document ",
    body: "Are you sure, you want to Request the Document?"
  },
  ReRequested: {
    title: "Re-Request Document ",
    body: "Are you sure, you want to Re-Request the Document?"
  },
  Received: {
    title: "Mark document as Received",
    body: "Are you sure, you want to mark the document as Received?"
  },
  Approve: {
    title: "Mark document as Approve",
    body: "Are you sure, you want to mark the document as Approved?"
  },
  Sign: {
    title: "Mark document as Signed",
    body: "Are you sure, you want to mark the document as Signed?"
  },
  ReRequestAll: {
    title: "Request Documents",
    body: "Are you sure, you want to Mark Requested, all the selected documents?"
  }
}

export const CASE_FORM_ACTION = {
  Sent: {
    title: "Send form ",
    body: "Are you sure, you want to Send the form?"
  },
  Approve: {
    title: "Mark form as Approve",
    body: "Are you sure, you want to mark the form as Approved?"
  },
  Sign: {
    title: "Mark form as Signed",
    body: "Are you sure, you want to mark the form as Signed?"
  }
}

export const VISA_CATEGORY_OPTIONS = [
  { label: "Employment - 1st", value: "Employment - 1st" },
  { label: "Employment - 2nd", value: "Employment - 2nd" },
  { label: "Employment - 3rd", value: "Employment - 3rd" },
  { label: "Employment - 4th", value: "Employment - 4th" },
  { label: "Employment - Other Workers", value: "Employment - Other Workers" },
  { label: "5th Non-Regional Center (C5 and T5)", value: "5th Non-Regional Center (C5 and T5)" },
  { label: "5th Regional Center (I5 and R5)", value: "5th Regional Center (I5 and R5)" },
  { label: "Family Based", value: "Family Based" },
];


export const RECEIVED_FROM_OPTIONS = [
  { label: "USCIS – California Service Center", value: "USCIS – California Service Center" },
  { label: "USCIS – Nebraska Service Center", value: "USCIS – Nebraska Service Center" },
  { label: "USCIS – Potomac Service Center", value: "USCIS – Potomac Service Center" },
  { label: "USCIS – Texas Service Center", value: "USCIS – Texas Service Center" },
  { label: "USCIS – Vermont Service Center", value: "USCIS – Vermont Service Center" },
  { label: "USCIS Field Office", value: "USCIS Field Office" },
  { label: "USCIS Asylum Office", value: "USCIS Asylum Office" },
  { label: "HART Service Center", value: "HART Service Center" },
  { label: "US Department of State", value: "US Department of State" },
  { label: "DOL - Department of Labor", value: "DOL - Department of Labor" },
  { label: "NBC - National Benefits Center", value: "NBC - National Benefits Center" },
  { label: "NVC - National Visa Center", value: "NVC - National Visa Center" },
  { label: "SWA – State Workforce Agency", value: "SWA – State Workforce Agency" },
  { label: "Other", value: "Other" },
];

export const NOTICE_TYPE_OPTIONS = [
  { label: "Receipt Notice", value: "Receipt Notice" },
  { label: "RFE Notice", value: "RFE Notice" },
  { label: "NOID Notice", value: "NOID Notice" },
  { label: "Approval Notice", value: "Approval Notice" },
  { label: "Withdrawal Decision", value: "Withdrawal Decision" },
  { label: "Denial Decision", value: "Denial Decision" },
  { label: "Transfer Notice", value: "Transfer Notice" },
  { label: "Biometrics Notice", value: "Biometrics Notice" },
  { label: "Interview Notice", value: "Interview Notice" },
  { label: "NVC Notice", value: "NVC Notice" },
  { label: "NVC Invoice", value: "NVC Invoice" },
  { label: "IV Fee Payment Receipt", value: "IV Fee Payment Receipt" },
  { label: "AOS Fee Payment Receipt", value: "AOS Fee Payment Receipt" },
  { label: "NVC RFE", value: "NVC RFE" },
  { label: "NVC Documentarily Complete", value: "NVC Documentarily Complete" },
  { label: "Termination Letter", value: "Termination Letter" },
  { label: "Revocation Notice", value: "Revocation Notice" },
  { label: "AA Confirmation Number", value: "AA Confirmation Number" },
  { label: "Perm Prevailing Wage", value: "Perm Prevailing Wage" },
  { label: "Other", value: "Other" },
];

export const RECEIPT_STATUS_OPTIONS = [
  { label: "Pending", value: "Pending" },
  { label: "RFE", value: "RFE" },
  { label: "NOID", value: "NOID" },
  { label: "Withdrawn", value: "Withdrawn" },
  { label: "Denied", value: "Denied" },
  { label: "Approved", value: "Approved" },
  { label: "Refile", value: "Refile" },
  { label: "Appeal", value: "Appeal" },
];

export const NOTIFY_ON_OPTIONS = [
  { label: "Both", value: "both" },
  { label: "Email", value: "email" },
  { label: "Bell", value: "bell" },
];

export const REMINDER_STATUS_OPTIONS = [
  { label: "Status: All", value: "" },
  { label: "Completed", value: "Completed" },
  { label: "Upcoming", value: "Upcoming" },
  { label: "Overdue", value: "Overdue" },
];

export const REMINDER_TIME_OPTIONS = [
  { label: "08:00:00", value: "08:00:00" },
  { label: "09:00:00", value: "09:00:00" },
  { label: "10:00:00", value: "10:00:00" },
  { label: "11:00:00", value: "11:00:00" },
  { label: "12:00:00", value: "12:00:00" },
  { label: "13:00:00", value: "13:00:00" },
  { label: "14:00:00", value: "14:00:00" },
  { label: "15:00:00", value: "15:00:00" },
  { label: "16:00:00", value: "16:00:00" },
  { label: "17:00:00", value: "17:00:00" },
  { label: "18:00:00", value: "18:00:00" },
  { label: "19:00:00", value: "19:00:00" },
  { label: "20:00:00", value: "20:00:00" },
];

export const YES_NO_OPTIONS = [
  { label: "Select", value: "" },
  { label: "Yes", value: true },
  { label: "No", value: false }
];
export const EVENT_TYPES_OPTIONS = [
  { label: "Appointment", value: "Appointment" },
  { label: "Court Date", value: "Court Date" },
  { label: "Interview", value: "Interview" },
  { label: "Meeting", value: "Meeting" },
  { label: "Other", value: "Other" }
];

export const EVENTS_TIME_OPTIONS = [
  { "label": "06:00:00", "value": "06:00:00" },
  { "label": "06:30:00", "value": "06:30:00" },
  { "label": "07:00:00", "value": "07:00:00" },
  { "label": "07:30:00", "value": "07:30:00" },
  { "label": "08:00:00", "value": "08:00:00" },
  { "label": "08:30:00", "value": "08:30:00" },
  { "label": "09:00:00", "value": "09:00:00" },
  { "label": "09:30:00", "value": "09:30:00" },
  { "label": "10:00:00", "value": "10:00:00" },
  { "label": "10:30:00", "value": "10:30:00" },
  { "label": "11:00:00", "value": "11:00:00" },
  { "label": "11:30:00", "value": "11:30:00" },
  { "label": "12:00:00", "value": "12:00:00" },
  { "label": "12:30:00", "value": "12:30:00" },
  { "label": "13:00:00", "value": "13:00:00" },
  { "label": "13:30:00", "value": "13:30:00" },
  { "label": "14:00:00", "value": "14:00:00" },
  { "label": "14:30:00", "value": "14:30:00" },
  { "label": "15:00:00", "value": "15:00:00" },
  { "label": "15:30:00", "value": "15:30:00" },
  { "label": "16:00:00", "value": "16:00:00" },
  { "label": "16:30:00", "value": "16:30:00" },
  { "label": "17:00:00", "value": "17:00:00" },
  { "label": "17:30:00", "value": "17:30:00" },
  { "label": "18:00:00", "value": "18:00:00" },
  { "label": "18:30:00", "value": "18:30:00" },
  { "label": "19:00:00", "value": "19:00:00" },
  { "label": "19:30:00", "value": "19:30:00" },
  { "label": "20:00:00", "value": "20:00:00" },
  { "label": "20:30:00", "value": "20:30:00" },
  { "label": "21:00:00", "value": "21:00:00" },
  { "label": "21:30:00", "value": "21:30:00" },
  { "label": "22:00:00", "value": "22:00:00" }
]





